<template>
  <div class="text-center w-100 mb-6">
    <TTBtn
      fab
      depressed
      color="white"
      elevation="1"
      large
      data-test="report-progress-aside-right-download-csv-button"
      class="mb-2"
      @click="$root.$emit('downloadReportProgress')"
    >
      <VIcon
        color="tt-black"
        size="19"
      >
        fal fa-arrow-to-bottom
      </VIcon>
    </TTBtn>
    <div class="tt-text-caption tt-black--text text--lighten-2">
      Выгрузить<br>
      отчет
    </div>
  </div>
</template>
<script>

export default {
  name: 'AppReportProgressAsideRight',
};
</script>

<style>
/* stylelint-ignore no-empty-source */
</style>
